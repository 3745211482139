import React from "react";
import vg from "../assets/2.webp";
import {
  AiFillGoogleCircle,
  AiFillAmazonCircle,
  AiFillYoutube,
  AiFillInstagram,
} from "react-icons/ai";

const Home = () => {
  return (
    <>
      <div className="home" id="home">
        <main>
          <h1>JoshStar</h1>
          <p>Solution to all your problems</p>
        </main>
      </div>

      <div className="home2">
        <img src={vg} alt="V Graphics" />
        <div>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nihil
            neque repellat et sunt praesentium, unde possimus ullam quo porro
            at!
          </p>
        </div>
      </div>

      <div className="home3" id="about">
        <div>
          <h1>Who We Are?</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo
            voluptas omnis officia maiores quidem exercitationem reprehenderit
            ex praesentium corrupti. Distinctio incidunt asperiores quaerat
            veritatis aperiam unde, possimus consequuntur culpa vel deleniti
            nostrum iure fugiat ducimus aspernatur tenetur animi velit
            repudiandae ex eius at. Minima temporibus nulla voluptates, laborum
            sit earum pariatur expedita aut dolore cumque commodi fugit tenetur
            atque, natus cupiditate
          </p>
        </div>
      </div>

      <div className="home4" id="brands">
        <div>
          <h1>Brands</h1>
          <article>
            <div style={{animationDelay: "0.3s" }}>
              <AiFillGoogleCircle/>
              <p>Google</p>
            </div>
            <div style={{animationDelay: "0.5s" }}>
              <AiFillAmazonCircle/>
              <p>Amazon</p>
            </div>
            <div style={{animationDelay: "0.7s" }}>
              <AiFillYoutube/>
              <p>Youtube</p>
            </div>
            <div style={{animationDelay: "1s" }}>
              <AiFillInstagram/>
              <p>Instagram</p>
            </div>
          </article>
        </div>
      </div>


      
    </>
  );
};

export default Home;

import React from 'react'

const Footer = () => {
  return (
    <footer>
        <div>
            <h1>JoshStar</h1>
            <p>@all right reserved</p>
        </div>
        <div>
            <h5>Follow Us</h5>
            <div>
                <a href="https://www.youtube.com/watch?v=hcMzwMrr1tE&ab_channel=T-Series" target={"blank"}>Youtube</a>
                <a href="https://www.youtube.com/watch?v=hcMzwMrr1tE&ab_channel=T-Series" target={"blank"}>Insta</a>
                <a href="https://www.youtube.com/watch?v=hcMzwMrr1tE&ab_channel=T-Series" target={"blank"}>GitHub</a>
            </div>
        </div>
        
    </footer>
  )
}

export default Footer
